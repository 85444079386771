import * as React from "react"

export default () => {
  return (
    <footer className="site-footer">
      <div className="container">
        <span className="copyright">
          © Copyright {(new Date().getFullYear())}
        </span>
        <span>
          &nbsp;mopfloorcheck.com - Smedeholm 10, 1. tv, 2730 Herlev - Tlf.: +45 44 64 15 10
      </span>
      </div>
    </footer>
  )
}
